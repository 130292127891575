<template>
    <div class="register-center">
        <login-header :type="type"></login-header>
        <div class="register-right">
            <transition name="el-zoom-in-top">
                <div class="login-center-r" style="margin:0" v-if="registerShow">
                    <div>Register</div>
                    <div>{{$t('login.title')}}</div>
                    <div>
                        <div class="login-center-r-input">
                            <input type="text" @change="userChong" v-model="userName" :placeholder="$t('login.username')" />
                            <i class="iconfont icon-bussiness-man"></i>
                        </div>
                        <div class="login-center-r-text">
                            <span v-if="userNumber == 1">{{$t("verification.veriOne")}}</span>
                            <span v-if="userNumber == 2">{{$t("verification.veriFive")}}</span>
                        </div>
                        <div class="login-center-r-input">
                            <input type="text" v-model="email" :placeholder="$t('login.email')" />
                            <i class="iconfont icon-email-fill"></i>
                        </div>
                        <div class="login-center-r-text"></div>
                        <div class="login-center-r-input">
                            <input type="password" v-model="pwd" :placeholder="$t('login.password')" />
                            <i class="iconfont icon-mima"></i>
                        </div>
                        <div class="login-center-r-text"></div>
                        <div class="login-center-r-input">
                            <input type="password" @input="pwdChange" v-model="passWord" :placeholder="$t('login.pwd')" />
                            <i class="iconfont icon-mima"></i>
                        </div>
                        <div class="login-center-r-text">
                            <span v-if="pwdNumber == 1">{{$t("verification.veriThree")}}</span>
                        </div>
                        <div class="login-center-r-input" style="border:none">
                            <el-radio-group v-model="sex" style="display:flex">
                                <el-radio label="women">
                                    <i class="iconfont icon-xingbienv1 register-icon" style="background:#fe3cd8"></i>
                                </el-radio>
                                <el-radio label="men">
                                    <i class="iconfont icon-xingbienan1 register-icon" style="background:#24a9fe"></i>
                                </el-radio>
                            </el-radio-group>
                            <i class="iconfont icon-kehuquanyi"></i>
                        </div>
                        <div class="login-center-r-text"></div>
                    </div>
                    <div>
                        <div></div>
                        <div @click="goLogin">{{$t('login.confirm')}}</div>
                    </div>
                </div>
            </transition>
        </div>
        <login-bottom></login-bottom>
    </div>
</template>
<script>
import sha256 from 'js-sha256'
import md5 from "js-md5"
import LoginHeader from "../components/loginHeader.vue"
import LoginBottom from "../components/loginBotton.vue"
export default {
    components: {LoginHeader,LoginBottom},
    data() {
        return{
            registerShow: false,
            userName: "",
            nickName: "",
            passWord: "",
            pwd: "",
            email: "",
            sex: "men",
            type: "register",
            userNumber: 100,
            pwdNumber: 100,
            servicerId: ""

        }
    },
    created(){
        this.userNumber = 101
        this.pwdNumber = 101
        if(sessionStorage.servicerId){
            this.servicerId = sessionStorage.servicerId
        }else{
            this.servicerId = ""
        }
    },
    mounted(){
        setTimeout(()=>{
            this.registerShow = true
        },1000)
    },
    methods: {
        // 用户名是否注册效验
        userChong(){
            if(this.userName){
                this.$isAxios.axiosGet(this.$isApi.userName,{userName:this.userName},(res)=>{
                    if(res.data.result == 'OK'){
                        this.userNumber = 100
                    }else{
                        this.userNumber = 2
                    }
                })
            }else{
                this.userNumber = 1
            }
        },
        // 两次密码是否相同效验
        pwdChange(){
            if(this.pwd != this.passWord){
                this.pwdNumber = 1
            }else{
                this.pwdNumber = 100
            }
        },
        goLogin(){
            var json = {
                userName: this.userName,
                passWord: sha256(md5(this.passWord)),
                email: this.email,
                sex: this.sex,
                servicerId: this.servicerId
            }
            if(this.userNumber == 100){
                if(this.pwdNumber == 100){
                    this.$isAxios.axiosRegister(this.$isApi.register,json,(res)=>{
                        if(res.data.result == 'success'){
                            sessionStorage['servicerId'] = ""
                            sessionStorage["token"] = res.data.token
                            sessionStorage['sendForm'] = JSON.stringify(res.data.userInfo)
                            this.$message({
                                message: '注册成功，正在跳转首页',
                                type: 'success',
                                duration: 2000
                            })
                            setTimeout(()=>{
                                this.$router.push({
                                    path: "/page/home"
                                })
                            },2000)
                        }else{
                            sessionStorage["token"] = ''
                            sessionStorage['sendForm'] = ""
                            this.$message.error('操作失败，请稍后再试')
                        }
                    })
                }else{
                    this.pwdNumber = 1
                    return false
                }
            }else{
                this.userNumber = 1
                return false
            }
        }
    }
}
</script>

<style scoped>
.register-center{
    width: 100vw;
    height: calc( 100vh - 30px );
    background: url("../assets/images/login_bj.jpg") no-repeat;
    position:fixed;
    top: 0;
    left: 0;
    min-width: 1000px;
    z-index:-10;
    zoom: 1;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}
.register-right{
    background: -moz-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    background: -webkit-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    background: -o-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    background: -ms-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    height: 100%;
    animation: myfirst 1s forwards;
	-webkit-animation: myfirst 1s forwards;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20vw;
    width: max-content;
}
@keyframes myfirst{
    from{
        width: 1px;
    }
    to{
        width: 60vw;
    }
}
@-webkit-keyframes myfirst{
	from{
        width: 1px;
    }
    to{
        width: 60vw;
    }
}
.register-icon{
    font-size: 14px;
    color: #fff;
    display: block;
    margin-bottom: 4px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
}
</style>