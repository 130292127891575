<template>
    <div class="page-bottom">
        <div class="page-bottom-center">
            <div class="page-bottom-center-r">
                <div v-html="copy_right_logo"></div>
            </div>
            <div class="page-bottom-center-l">
                <div>
                    <div @click="goContent(1)">{{$t('pageBottom.aboutUs')}}</div>
                    <div @click="goContent(2)">{{$t('pageBottom.clause')}}</div>
                    <div @click="goContent(3)">{{$t('pageBottom.privacy')}}</div>
                    <div @click="goContent(4)">{{$t('pageBottom.safety')}}</div>
                    <div @click="goContent(5)">{{$t('pageBottom.contactUs')}}</div>
                    <div @click="goContent(6)">{{$t('pageBottom.center')}}</div>
                    <div @click="goContent(7)">{{$t('pageBottom.refund')}}</div>
                </div>
                <div>
                    <span>Copyright©</span>
                    <span>{{myDate}}</span>
                    <span>{{copy_right_company}}</span>
                    <span>All Rights Reserved.</span>
                    <!-- <span>Jingkun Tech Limited(71-75 Shelton Street, Covent Garden, London, United Kingdom) All Rights Reserved</span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            pay_001: require('../assets/images/pay_001.png'),
            pay_002: require('../assets/images/pay_002.png'),
            myDate: "",
            copy_right_logo: "",
            copy_right_company: ""
        }
    },
    mounted(){
        this.myDate = (new Date()).getFullYear()
        this.$nextTick(()=>{
            this.copy_right_logo = copy_right_logo
            this.copy_right_company = copy_right_company
        })
    },
    methods: {
        goContent(num){
            sessionStorage['number'] = num
            this.$nextTick(()=>{
                this.$router.push({
                    path: "/loginContent"
                })
            })
        }
    }
}
</script>

<style scoped>
.page-bottom{
    height: max-content;
    background: #FD463E;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}
.page-bottom-center{
    width: 1200px;
    height: max-content;
    margin: 0 auto;
    padding: 30px 0
}
.page-bottom-center-l{
    height: max-content;
}
.page-bottom-center-l>div{
    display: flex;
    width: max-content;
    flex-grow: 0;
}
.page-bottom-center-l>div:nth-child(1){
    display: flex;
}
.page-bottom-center-l>div:nth-child(1)>div{
    font-size: 14px;
    margin-right: 25px;
    padding-right: 25px;
    height: 14px;
    line-height: 14px;
    cursor: pointer;
    border-right: 1px solid rgba(255, 255, 255, .6);
    color: #FFF;
}
.page-bottom-center-l>div:nth-child(1)>div:last-child{
    border: none
}
.page-bottom-center-l>div:nth-child(1)>div>div:hover{
    text-decoration: underline
}
.page-bottom-center-l>div:nth-child(2){
    display: flex;
    margin-top: 14px;
    color: #fff;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    margin-top: 8px
}
.page-bottom-center-r{
    width: max-content;
    display: flex;
    margin-bottom: 20px
}
.page-bottom-center-r>img{
    display: block;
    background: rgba(255, 255, 255, .8);
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 20px;
}
</style>